import React, { Component } from "react"
import { graphql } from "gatsby"
import AOS from 'aos'
import { Helmet } from "react-helmet"
import Layout from "../layouts"

function myFunction() {
  var winScroll = document.body.scrollTop || document.documentElement.scrollTop;
  var height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
  var scrolled = (winScroll / height) * 100;
  document.getElementById("progress-bar").style.width = scrolled + "%";
}

class PageTemplate extends Component {

  componentDidMount() {
    AOS.init();
    window.onscroll = function() {
      myFunction();
    };
    window.onload = function() {
      AOS.refresh();
    }
  }

  render() {
    const currentPage = this.props.data.wordpressPage
    let house = '';

    let acfColor = '#F3A738';

    if(currentPage !== null){
      if(currentPage.acf.meta_image === null){
        currentPage.acf = {
          meta_description: '',
          meta_image: {
            url: ''
          }
        }
      }
    }
    if (typeof window === 'undefined'){
      return (
        <Layout>
          <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | {currentPage.title}</title>
          <meta name="description" content={currentPage.acf.meta_description} />
          <meta property="og:title" content={"Excel | " + currentPage.title}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={currentPage.acf.meta_image.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={currentPage.acf.meta_description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | " + currentPage.title} />
          <meta name="twitter:description" content={currentPage.acf.meta_description} />
          <meta name="twitter:image" content={currentPage.acf.meta_image.url} />
          
        </Helmet>
      </Layout>
    )
  }else{
    var called = false
    window.addEventListener('mousedown', function() {
      if (called === false){
          let elem = document.getElementById('excel-global-header-inner')
          elem.classList.add('using-mouse');
          called = true
      }
    });

    return(
      <Layout>
          <Helmet script={[{
            type: 'application/ld+json',
            innerHTML: JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebPage",
              "url": "https://excelinstore.com/",
              "name": "Excel | Moving Retail Forward",
              "author": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  },
                  "contactPoint": {
                      "@type": "ContactPoint",
                      "contactType": "customer support",
                      "telephone": "+1-218-998-3800",
                      "email": "contact@excelinstore.com"
                  }
              },
              "description": "We guide clients through the creation of in-store visual merchandising displays and fixtures.",
              "publisher": {
                  "@type": "Organization",
                  "name": "Excel",
                  "url": "https://excelinstore.com/",
                  "address": {
                      "@type": "PostalAddress",
                      "streetAddress": "1021 International Drive",
                      "addressLocality": "Fergus Falls",
                      "addressRegion": "MN",
                      "postalCode": "56537",
                      "addressCountry": "USA"
                  }
              }
            })
          }]}>
          <html lang="en" />
          <meta charSet="utf-8" />
          <title>Excel | {currentPage.title}</title>
          <meta name="description" content={currentPage.acf.meta_description} />
          <meta property="og:title" content={"Excel | " + currentPage.title}  />
          <meta property="og:type" content="WebSite" />
          <meta property="og:url" content="https://excelinstore.com/" />
          <meta property="og:image" content={currentPage.acf.meta_image.url} />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:description" content={currentPage.acf.meta_description} />
          <meta property="og:site_name" content="Excel" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={"Excel | " + currentPage.title} />
          <meta name="twitter:description" content={currentPage.acf.meta_description} />
          <meta name="twitter:image" content={currentPage.acf.meta_image.url} />
          
        </Helmet>
        <div className="progress"><div id="progress-bar" style={{backgroundColor: acfColor}}></div></div>
        <div className="hero--wrapper">
          <div className="hero long page">
            <div className="title h2 longer" data-aos="fade-up" data-aos-delay="300" data-aos-anchor=".hero" dangerouslySetInnerHTML={{ __html: currentPage.title }}/>
          </div>
        </div>
        
        <div className="content-wrapper-long content-container" dangerouslySetInnerHTML={{ __html: currentPage.content }} data-aos="fade-up" data-aos-delay="600" data-aos-anchor=".hero--wrapper"/>
      </Layout>
    )
  }
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      acf {
        meta_image{
          alt
          url
        }
        meta_description
      }
    }
    site {
      id
      siteMetadata {
        title
        subtitle
      }
    }
  }
`
